import React, { useState, useContext, useEffect } from 'react';
import { Helper } from 'utils';
import { Api } from 'services';
import CONFIG from "config";
import { withRouter } from "react-router-dom";
import { AppActions } from 'store';
import { StateContext, DispatchContext } from 'context';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faMinus, faPlus, faChevronLeft, faChevronRight, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

import './pedidos.css';
import { useSnackbar } from 'notistack';

import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Geocode from "react-geocode";

import { Colors } from 'styles';


import { Boton, IconButtonSmall, IconButtonExtraSmall } from 'components/material-ui';

import AsyncSelect from 'react-select/async';
import { OPCIONES_TIPOS_PEDIDO_PARA_CREACION, isConEnvio } from 'config/pedidos';
import { GoogleMap, SelectorUbicaciones } from 'components/GoogleMap';

const useSelectStyles = makeStyles((theme) => ({
	formControl: {
		margin: '0px 2px',
		minWidth: 200
	},
	selectEmpty: {
		marginTop: '0px'
	},
}));
const BootstrapInput = withStyles((theme) => ({
	input: {
		fontSize: 16,
		padding: '10px 26px 10px 12px',
	},
}))(InputBase);
const RadioTheme = withStyles({
	root: {
		color: Colors.THEME,
		'&checked': {
			color: Colors.THEME_D1,
		},
	}
})((props) => <Radio color="default" {...props} />);
const useStyles = makeStyles((theme) => ({
	margin: {
		margin: theme.spacing(1),
	},
	extendedIcon: {
		marginRight: theme.spacing(1),
	},
}));


Geocode.setApiKey("AIzaSyCaw8ahy7ztFKt6aq9kbqVlM1Bk_YmUU9s");
Geocode.setLanguage("en");
const CrearPedido = (props) => {
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();
	const dispatch = useContext(DispatchContext);
	const state = useContext(StateContext);

	const opcionesTiposPedido = OPCIONES_TIPOS_PEDIDO_PARA_CREACION

	const [subTotal, setSubTotal] = useState(0);
	const [total, setTotal] = useState(0);
	const [tipo, setTipo] = useState(opcionesTiposPedido[0].value);
	const [moneda, setMoneda] = useState('₲');
	const [miUbicacionHabilitada, setMiUbicacionHabilitada] = useState(false);
	const [ubicacion, setUbicacion] = useState(null);
	const [mapas, setMapas] = useState(null);
	const [mapa, setMapa] = useState(null);
	const [datosUbicacion, setDatosUbicacion] = useState(null);
	const [tabUbicacion, setTabUbicacion] = useState('mapa');
	const [clienteSelected, setClienteSelected] = useState(null);
	const [ubicaciones, setUbicaciones] = useState(null);
	const [precioEnvio, setPrecioEnvio] = useState(0)
	const [pedido, setPedido] = useState({
		abm: 'nuevo',
		items: [],
		nombre: '',
		apellido: '',
		ruc: '',
		telefono: '',
		usuarioId: null,
		razonSocial: ''
	});
	const [errores, setErrores] = useState({});
	const selectClass = useSelectStyles();
	const [filtro, setFiltro] = useState({});
	const [productos, setProductos] = useState([]);
	const [categorias1, setCategorias1] = useState([]);
	const [categorias2, setCategorias2] = useState([]);
	const [categorias3, setCategorias3] = useState([]);
	const [configuraciones, setConfiguraciones] = useState(null);
	const [filtroJerarquia, setFiltroJerarquia] = useState({
		nivel1: 'todos',
		nivel2: 'todos',
		nivel3: 'todos'
	});

	const conPuntoEntrega = isConEnvio(tipo)
	const poligono = state.appReducer.areaCobertura[datosUbicacion?.indicePoligono]
	const envio = poligono ? { precio: poligono.precio, descripcion: poligono.nombre } : null

	useEffect(() => {
	}, []);

	useEffect(() => {
		//console.log('pedido', pedido);
	}, [pedido]);

	useEffect(() => {
		let sTotal = 0;
		for (var i in pedido.items) {
			sTotal = sTotal + (pedido.items[i].precio * pedido.items[i].cantidad);
		}
		let tl = sTotal;
		if (conPuntoEntrega) {
			tl += precioEnvio;
		}
		setSubTotal(sTotal);
		setTotal(tl);
	}, [pedido, precioEnvio]);

	useEffect(() => {
		let precio = 0

		if (isConEnvio(tipo)) {
			precio = (poligono?.precio || 0);
		}

		setPrecioEnvio(precio)
	}, [datosUbicacion?.indicePoligono])


	// let searchInput = React.createRef();
	const [requestEnProceso, setRequestEnProceso] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [typing, setTyping] = useState(false);
	const [typingTimeout, setTypingTimeout] = useState(0);
	const [paginador, setPaginador] = useState({
		cuantos: 10,
		selectCuantos: [{ label: '10', value: 10 }, { label: '20', value: 20 }, { label: '30', value: 30 }, { label: '50', value: 50 }, { label: '100', value: 100 }],
		numResultados: 0,
		paginaActual: 0,
		cantidadPaginas: 1,
		maxSize: 10
	});
	useEffect(() => {
		//console.log('filtro', filtro);
	}, [filtro]);
	const onChangeFiltro = (event, key) => {
		if (key === 'nivel1') {
			let cats2 = [];
			let cats3 = [];
			setFiltroJerarquia({
				nivel1: event.target.value,
				nivel2: 'todos',
				nivel3: 'todos',
			});
			for (var i in categorias1) {
				if (categorias1[i].id === event.target.value && categorias1[i].subJerarquias.length > 0) {
					cats2 = categorias1[i].subJerarquias;
				}
			}
			for (var x in cats2) {
				if (cats2[x].id === event.target.value && cats2[x].subJerarquias.length > 0) {
					cats3 = cats2[x].subJerarquias;

				}
			}
			setCategorias2(cats2);
			setCategorias3(cats3);
		}
		if (key === 'nivel2') {
			let cats3 = [];
			setFiltroJerarquia({
				nivel1: filtroJerarquia.nivel1,
				nivel2: event.target.value,
				nivel3: 'todos',
			});
			for (var y in categorias2) {
				if (categorias2[y].id === event.target.value && categorias2[y].subJerarquias.length > 0) {
					cats3 = categorias2[y].subJerarquias;
				}
			}
			setCategorias3(cats3);
		}
		if (key === 'nivel3') {
			setFiltroJerarquia({
				nivel1: filtroJerarquia.nivel1,
				nivel2: filtroJerarquia.nivel2,
				nivel3: event.target.value
			});
		}
		let fts = { ...filtros };
		if (event.target.value === 'todos') {
			if (key === 'nivel1') {
				fts.filtros.jerarquiaId = '';
			}
			if (key === 'nivel2') {
				fts.filtros.jerarquiaId = filtroJerarquia.nivel1;
			}
			if (key === 'nivel3') {
				fts.filtros.jerarquiaId = filtroJerarquia.nivel2;
			}
		} else {
			fts.filtros.jerarquiaId = event.target.value;
		}
		buscarResultados(fts);
	}
	const [filtros, setFiltros] = useState({
		cuantos: paginador.cuantos,
		desde: 0,
		filtros: {
			nombre: [''],
			jerarquiaId: ''
		}
	});
	useEffect(() => {
		// searchInput.current.focus();
	}, [productos]);
	useEffect(() => {
		//console.log('categorias1', categorias1);
	}, [categorias1]);
	const cambioCuantos = (event) => {
		const cts = event.target.value;
		const fts = { ...filtros, desde: 0, cuantos: cts };
		setPaginador({ ...paginador, paginaActual: 0, cuantos: cts });
		buscarResultados(fts);
	}
	const cambioTextoBusqueda = (event) => {
		setSearchText(event.target.value);
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		let texto = (!event.target.value) ? "" : event.target.value;
		let fts = { ...filtros };
		fts.filtros.nombre[0] = texto;
		setTyping(false);
		setTypingTimeout(setTimeout(() => buscarResultados(fts), 1500));
	}
	const getConfig = (fts) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('a/config-pedidos'),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getConfiguraciones response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setConfiguraciones(response.data.datos);
				dispatch(AppActions.setAreaCobertura(response.data.datos.areaCobertura));
				if (response.data && response.data.datos && response.data.datos.jerarquias) {
					setCategorias1(Helper.armarCategorias([...response.data.datos.jerarquias]));
				}
				buscarResultados(fts);
			}
		}, error => {
			Helper.log('error getConfiguraciones', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al traer datos de configuración', { variant: 'error' });
		});
	}
	const buscarResultados = (fts) => {
		setFiltros(fts);
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		setRequestEnProceso(true);
		var options = {
			url: Api.route('a/ver-items'),
			method: 'POST',
			data: fts
		};
		Api.requestAxios(options, response => {
			Helper.log('searchProductos response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				setPaginador({
					...paginador,
					numResultados: response.data.datos.numResultados,
					cantidadPaginas: Math.ceil(response.data.datos.numResultados / fts.cuantos)
				});
				setProductos(response.data.datos.items);
			}
			setRequestEnProceso(false);
		}, error => {
			Helper.log('error searchProductos', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			setRequestEnProceso(false);
			enqueueSnackbar('No se pudo traer los productos', { variant: 'error' });
		});
	}
	const atras = () => {
		if (filtros.desde > 0) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual - 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual - 1 }));
				buscarResultados(fts);
			}
		}
	}
	const siguiente = () => {
		if (filtros.desde + paginador.cuantos < paginador.numResultados) {
			if (!requestEnProceso) {
				const fts = { ...filtros, desde: paginador.cuantos * (paginador.paginaActual + 1) };
				setFiltros(fts);
				setPaginador((pgndr) => ({ ...pgndr, paginaActual: paginador.paginaActual + 1 }));
				buscarResultados(fts);
			}
		}
	}


	const cerrar = () => {
		props.cancelar();
		props.buscarResultados(props.filtros);
	};
	const handleChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		setPedido({ ...pedido, [name]: value });
		setErrores({ ...errores, [name]: false });
	};
	const guardar = () => {
		let todoBien = true;
		let data = {
			tipo
		};
		let errores = {};
		const { usuarioId, items, razonSocial, ruc } = pedido;
		//usuarioId
		if (usuarioId) {
			data.usuarioId = usuarioId;
			errores.usuarioId = false;
		} else {
			todoBien = false;
			errores.usuarioId = true;
		}

		let datosFacturacion = {};
		//razonSocial
		if (razonSocial) {
			datosFacturacion.razonSocial = razonSocial;
			errores.razonSocial = false;
		} else {
			// todoBien = false;
			// errores.razonSocial = true;
		}
		//ruc
		if (ruc) {
			datosFacturacion.ruc = ruc;
			errores.ruc = false;
		} else {
			// todoBien = false;
			// errores.ruc = true;
		}
		if (razonSocial || ruc) {
			data.datosFacturacion = datosFacturacion;
		}
		if (items && items.length > 0) {
			data.items = [];
			for (var i in items) {
				data.items.push({
					itemId: items[i].itemId,
					cantidad: items[i].cantidad
				});
			}
		} else {
			todoBien = false;
			errores.items = true;
		}

		if (conPuntoEntrega && !datosUbicacion) {
			todoBien = false;
			errores.puntoEntregaId = true;
		}

		setErrores(errores);
		if (todoBien) {
			if (conPuntoEntrega && !datosUbicacion.id) {
				guardarUbicacion(data);
			} else if (conPuntoEntrega && datosUbicacion.id) {
				data.puntoEntregaId = datosUbicacion.id;
				setPedido({
					...pedido,
					puntoEntregaId: datosUbicacion.id
				});
				subirDatos(data);
			} else {
				subirDatos(data);
			}
		}
	};

	const getUbicaciones = (clienteId) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route(`a/usuarios/${clienteId}/ubicaciones`),
			method: 'GET'
		};
		Api.requestAxios(options, response => {
			Helper.log('getUbicaciones response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data && response.data.datos) {

					const ubns = [];
					for (var i in response.data.datos) {
						for (var i in response.data.datos) {
							ubns.push(response.data.datos[i]);
						}
					}
					setUbicaciones(ubns);
				}
			}
		}, error => {
			Helper.log('error getUbicaciones', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
		});
	}


	const guardarUbicacion = (datos) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('p/nueva-ubicacion'),
			data: {
				lat: datosUbicacion.lat,
				lon: datosUbicacion.lng,
				direccion: datosUbicacion.direccion,
				barrio: datosUbicacion.barrio,
				ciudad: datosUbicacion.ciudad,
				observaciones: datosUbicacion.observaciones
			},
			method: 'POST'
		};
		Api.requestAxios(options, response => {
			Helper.log('nuevaUbicacion response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data && response.data.datos) {
					let data = {
						...datos,
						puntoEntregaId: response.data.datos
					};
					setPedido({
						...pedido,
						puntoEntregaId: response.data.datos
					});
					subirDatos(data);
				}
			}
		}, error => {
			Helper.log('error nuevaUbicacion', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al subir nuevo producto', { variant: 'error' });
		});
	}
	const elegirUbicacion = (ubcn) => {
		setDatosUbicacion({
			id: ubcn.id,
			lat: parseFloat(ubcn.lat),
			lng: parseFloat(ubcn.lon),
			direccion: ubcn.direccion,
			barrio: ubcn.barrio,
			ciudad: ubcn.ciudad,
			observaciones: ubcn.observaciones,
		});
	}
	const subirDatos = (data) => {
		dispatch(AppActions.setLoading(true));
		dispatch(AppActions.setActionsDisabled(true));
		var options = {
			url: Api.route('a/pedidos'),
			data: data,
			method: 'POST'
		};
		Api.requestAxios(options, response => {
			Helper.log('nuevoPedido response', response);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				enqueueSnackbar(response.data.msg, { variant: 'success' });
				cerrar();
			}
		}, error => {
			Helper.log('error nuevoPedido', error);
			dispatch(AppActions.setLoading(false));
			dispatch(AppActions.setActionsDisabled(false));
			enqueueSnackbar('Error al subir nuevo pedido', { variant: 'error' });
		});
	};


	const traerProductos = () => {
		getConfig(filtros);
	}

	const menos = (index, item) => {
		let itm = { ...item };
		if (itm.cantidad === 1) {
			let items = [...pedido.items];
			items.splice(index, 1);
			setPedido({ ...pedido, items: items });
		} else {
			itm.cantidad = itm.cantidad - 1;
			let items = [...pedido.items];
			items[index] = itm;
			setPedido({ ...pedido, items: items });
		}
	}
	const mas = (index, item) => {
		let itm = { ...item };
		itm.cantidad = itm.cantidad + 1;
		let items = [...pedido.items];
		items[index] = itm;
		setPedido({ ...pedido, items: items });
	}
	const onChangeCantidad = (e, item) => {
		e.preventDefault();
		if (e && e.target && e.target.value) {
			let num = Number(e.target.value);
			if (Number.isInteger(num)) {
				item.cantidad = num;
				dispatch(AppActions.setItemsCarrito({ ...state.appReducer.itemsCarrito, [item.id]: item }));
			}
		}
	}
	const quitarDelPedido = (index, item) => {
		let items = [...pedido.items];
		items.splice(index, 1);
		setPedido({ ...pedido, items: items });
	}
	const agregarAlPedido = (item) => {
		let items = [...pedido.items];
		let esta = false;
		for (var i in items) {
			if (items[i].itemId === item.id) {
				items[i].cantidad = items[i].cantidad + 1;
				esta = true;
			}
		}
		if (!esta) {
			items.push({ itemId: item.id, cantidad: 1, precio: item.precio, nombre: item.nombre, multimedia: item.multimedia });
		}
		setPedido({ ...pedido, items: items });
	}

	const onChangeTipo = (event) => {
		setTipo(event.target.value);
		if (isConEnvio(event.target.value)) {
			setTabUbicacion('mapa');
		} else {
			setDatosUbicacion(null)
		}
	}

	const handleInputChange = (newValue) => {
		// const inputValue = newValue.replace(/\W/g, '');
		return newValue;
	};
	const onChangeSelectCliente = (newValue) => {
		setClienteSelected(newValue);
		setPedido({
			...pedido,
			usuarioId: newValue.id,
			nombre: newValue.nombre,
			apellido: newValue.apellido,
			ruc: newValue.ruc ? newValue.ruc : '',
			telefono: newValue.telefono ? newValue.telefono : '',
			razonSocial: newValue.nombre + ' ' + newValue.apellido
		});
		getUbicaciones(newValue.id);
	};

	const mapOptionsToValues = options => {
		return options.map(option => ({
			...option,
			value: option.id,
			label: option.nombre + ' ' + option.apellido
		}));
	};

	const loadOptions = async (inputValue, callback) => {
		Api.requestAxios({
			url: Api.route('a/ver-clientes'),
			method: 'POST',
			data: {
				desde: 0,
				cuantos: 50,
				filtros: {
					nombre: [inputValue]
				}
			}
		}, response => {
			Helper.log('searchClientes response', response);
			if (response.data.error) {
				enqueueSnackbar(response.data.msg, { variant: 'error' });
			} else {
				if (response.data && response.data.datos && response.data.datos.usuarios) {
					callback(mapOptionsToValues(response.data.datos.usuarios));
				} else {
					callback(() => []);
				}
			}
		}, error => {
			Helper.log('error searchClientes', error);
			enqueueSnackbar('No se pudo traer los clientes', { variant: 'error' });
			callback(() => []);
		})
	};

	const promiseOptions = inputValue =>
		new Promise(resolve => {
			Api.requestAxios({
				url: Api.route('a/ver-clientes'),
				method: 'POST',
				data: {
					desde: 0,
					cuantos: 50,
					filtros: {
						nombre: [inputValue]
					}
				}
			}, response => {
				Helper.log('searchClientes response', response);
				if (response.data.error) {
					enqueueSnackbar(response.data.msg, { variant: 'error' });
				} else {
					if (response.data && response.data.datos && response.data.datos.usuarios) {
						resolve(mapOptionsToValues(response.data.datos.usuarios));
					} else {
						resolve(() => []);
					}
				}
			}, error => {
				Helper.log('error searchClientes', error);
				enqueueSnackbar('No se pudo traer los clientes', { variant: 'error' });
				resolve(() => []);
			});
		});


	return (
		<div id="crear-pedido">
			<div className="w3-padding-top bs-bbox">
				<form className="w3-margin-bottom w3-padding">
					<div className="w3-row">
						<div className="w3-col l7 w3-padding bs-bbox">
							<div className="w3-white bs-bbox w3-padding-large">
								<b>Datos cliente</b>
								{pedido ? <div className="w3-border w3-padding-small bs-bbox">
									<div className="w3-row">
										<div className="w3-col l6 w3-padding-small bs-bbox">
											<AsyncSelect
												cacheOptions
												defaultOptions
												value={clienteSelected}
												loadOptions={promiseOptions}
												onInputChange={handleInputChange}
												onChange={onChangeSelectCliente}
											/>
										</div>
									</div>

									{/* <div className="w3-row">
                                        <div className="w3-col l6 w3-padding-small bs-bbox">
                                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                                id="input-nombre"
                                                label="Nombre"
                                                type="text"
                                                name="nombre"
                                                value={pedido.nombre} />
                                        </div>
                                        <div className="w3-col l6 w3-padding-small bs-bbox">
                                            <TextField fullWidth margin="normal" size="small" variant="outlined"
                                                id="input-apellido"
                                                label="Apellido"
                                                type="text"
                                                name="apellido"
                                                value={pedido.apellido} />
                                        </div>
                                    </div> */}
									<div className="w3-row">
										<div className="w3-col l6 w3-padding-small bs-bbox">
											<TextField fullWidth margin="normal" size="small" variant="outlined"
												id="input-razonSocial"
												label="Razón social"
												type="text"
												name="razonSocial"
												value={pedido.razonSocial}
												onChange={handleChange}
												disabled={state.appReducer.actionsDisabled} />
										</div>
										<div className="w3-col l6 w3-padding-small bs-bbox">
											<TextField fullWidth margin="normal" size="small" variant="outlined"
												id="input-ruc"
												label="RUC"
												type="text"
												name="ruc"
												value={pedido.ruc}
												onChange={handleChange}
												disabled={state.appReducer.actionsDisabled} />
										</div>
									</div>
								</div> : null}
								<div style={{ paddingTop: '10px' }}>
									<b>Productos</b>
									{(pedido.items && pedido.items.length > 0) ? <table className="w3-table w3-border w3-bordered mi-tabla">
										<thead className="w3-border">
											<tr>
												<th></th>
												<th></th>
												<th>Producto</th>
												<th>Precio</th>
												<th>Cantidad</th>
												<th>Total</th>
											</tr>
										</thead>
										<tbody className="w3-border">
											{pedido.items.map((item, index) => <tr key={item.itemId}>
												<td>
													<IconButtonSmall aria-label="Eliminar" onClick={() => quitarDelPedido(index, item)}>
														<FontAwesomeIcon icon={faTrash} color="#DDDDDD" />
													</IconButtonSmall>
												</td>
												<td style={{ width: '100px' }}>
													<div className="thumb-container"
														style={{
															backgroundImage: "url('" + Api.route(item.multimedia[0].url) + "')"
														}}>
													</div>
												</td>
												<td>{item.nombre}</td>
												<td>{'₲ ' + Helper.numberUi(item.precio)}</td>
												<td style={{ width: '120px', padding: '8px 0px' }}>

													<FormControl variant="outlined" size="small">
														<InputLabel></InputLabel>
														<OutlinedInput
															id={"input-cantidad-" + item.itemId}
															type="text"
															fullWidth={true}
															value={item.cantidad}
															onChange={(e) => onChangeCantidad(e, item)}
															disabled={(state.appReducer.actionsDisabled) ? true : false}
															startAdornment={
																<InputAdornment position="start">
																	<IconButtonExtraSmall
																		aria-label="Menos"
																		onClick={() => menos(index, item)}
																		onMouseDown={e => e.preventDefault()}
																		edge="start"
																	>
																		<FontAwesomeIcon icon={faMinus} size="xs" />
																	</IconButtonExtraSmall>
																</InputAdornment>
															}
															endAdornment={
																<InputAdornment position="end">
																	<IconButtonExtraSmall
																		aria-label="Más"
																		onClick={() => mas(index, item)}
																		onMouseDown={e => e.preventDefault()}
																		edge="end"
																	>
																		<FontAwesomeIcon icon={faPlus} size="xs" />
																	</IconButtonExtraSmall>
																</InputAdornment>
															}
														/>
													</FormControl>
												</td>
												<td>{'₲ ' + Helper.numberUi(item.cantidad * item.precio)}</td>
											</tr>)}
										</tbody>
									</table> : null}

									{pedido.items.length > 0 ? <table className="w3-table w3-border w3-bordered">
										<tbody className="w3-border">
											<tr>
												<td className="w3-padding">
													<h3>Subtotal</h3>
												</td>
												<td className="w3-padding">
													<h3 style={{ textAlign: 'right' }}>{moneda} {Helper.numberUi(subTotal)}</h3>
												</td>
											</tr>
											<tr>
												<td className="w3-padding">
													<h4 style={{ marginBottom: 0 }}>Tipo</h4>
													<FormControl component="fieldset">
														<RadioGroup aria-label="tipo" name="tipo" value={tipo} onChange={(e) => onChangeTipo(e)}>
															{opcionesTiposPedido.map((opcion, i) => (
																<FormControlLabel key={`tipo-${i}`} value={opcion.value} control={<RadioTheme />} label={opcion.label} />
															))}
														</RadioGroup>
													</FormControl>
												</td>
												<td className="w3-padding">
													<h4 style={{ textAlign: 'right', marginBottom: 0 }}>&nbsp;</h4>
													{opcionesTiposPedido.map((opcion, i) => (
														<h4 key={`tipo-precio-${i}`} style={{ textAlign: 'right', marginBottom: 0, height: 42, lineHeight: '42px', marginTop: 0 }}>{moneda} {isConEnvio(opcion.value) ? Helper.numberUi(precioEnvio) : 0}</h4>
													))}
												</td>
											</tr>
											{conPuntoEntrega ? <tr>
												<td colSpan="2" className="w3-padding">
													<div>
														{(ubicaciones && ubicaciones.length > 0) ? (
															<FormControl component="fieldset">
																<RadioGroup aria-label="ubicacion" name="tab-ubicacion" value={tabUbicacion} onChange={(e) => setTabUbicacion(e.target.value)}>
																	<FormControlLabel value="lista" control={<RadioTheme />} label="Ubicaciones guardadas" />
																	<FormControlLabel value="mapa" control={<RadioTheme />} label="Indicar en el mapa" />
																</RadioGroup>
															</FormControl>
														) : null}
														<div style={{ marginTop: 15 }}>
															<GoogleMap
																mapHeight={500}
																mapWidth='100%'
																areas={state.appReducer.areaCobertura}
																ubicacion={datosUbicacion}
																setUbicacion={setDatosUbicacion}
																ubicaciones
																disabled={state.appReducer.actionsDisabled || tabUbicacion === 'lista'}
															/>
														</div>
														{tabUbicacion === 'lista' ? <div>
															<SelectorUbicaciones
																ubicaciones={ubicaciones}
																elegirUbicacion={elegirUbicacion}
																datosUbicacion={datosUbicacion}
															/>
														</div> : null}
													</div>
												</td>
											</tr> : null}
											<tr>
												<td className="w3-padding">
													<h3>Total</h3>
												</td>
												<td className="w3-padding">
													<h2 style={{ textAlign: 'right', color: Colors.THEME }}>{moneda} {Helper.numberUi(total)}</h2>
												</td>
											</tr>
										</tbody>
									</table> : null}

									{/* {productos.length === 0 ? <IconButton aria-label="delete"  color="primary" onClick={() => traerProductos()}>
                                        &nbsp;<FontAwesomeIcon icon={faPlus} size="1x"/>&nbsp;
                                    </IconButton> : null } */}
									<br />
									{productos.length === 0 ? <Boton onClick={() => traerProductos()} color="primary" variant="contained" size="small">Agregar productos</Boton> : null}
								</div>
							</div>
						</div>

						<div className="w3-col l5 w3-padding">

							<div>
								{(categorias1.length > 0) ? <FormControl variant="outlined" size="small" className={selectClass.formControl}>
									<Select
										MenuProps={{
											style: { zIndex: 15000 }
										}}
										labelId="demo-simple-select-outlined-1-label"
										id="demo-simple-select-outlined-1"
										value={filtroJerarquia['nivel1']}
										onChange={(e) => onChangeFiltro(e, 'nivel1')}
									>
										{categorias1.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
									</Select>
								</FormControl> : null}
								{(categorias2.length > 0) ? <FormControl variant="outlined" size="small" className={selectClass.formControl}>
									<Select
										MenuProps={{
											style: { zIndex: 15000 }
										}}
										labelId="demo-simple-select-outlined-2-label"
										id="demo-simple-select-outlined-2"
										value={filtroJerarquia['nivel2']}
										onChange={(e) => onChangeFiltro(e, 'nivel2')}
									>
										{categorias2.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
									</Select>
								</FormControl> : null}
								{(categorias3.length > 0) ? <FormControl variant="outlined" size="small" className={selectClass.formControl}>
									<Select
										MenuProps={{
											style: { zIndex: 15000 }
										}}
										labelId="demo-simple-select-outlined-3-label"
										id="demo-simple-select-outlined-3"
										value={filtroJerarquia['nivel3']}
										onChange={(e) => onChangeFiltro(e, 'nivel3')}
									>
										{categorias3.map((cat) => <MenuItem key={cat.id} value={cat.id}>{cat.nombre}</MenuItem>)}
									</Select>
								</FormControl> : null}
							</div>
							{productos.length > 0 ? <div className="w3-block w3-margin-top">
								<table className="w3-table w3-border w3-bordered tabla w3-white">
									<thead className="w3-border">
										<tr>
											<th>Id</th>
											<th>Nombre</th>
											<th>Categoría</th>
											<th>Precio</th>
											<th>Foto</th>
											<th></th>
										</tr>
									</thead>
									<tbody className="w3-border">
										{productos.map((item) => <tr key={item.id}>
											<td>{item.id}</td>
											<td>{item.nombre}</td>
											<td>{item.jerarquias.map((categ) => <div key={categ.id} className="xtag-gray">{categ.nombre}</div>)}</td>
											<td>{item.precio ? Helper.numberUi(item.precio) + ' Gs.' : '--'}</td>
											<td>
												<div className="thumb-container w3-gray"
													style={{
														backgroundImage: "url('" + (item.multimedia && item.multimedia[0] && item.multimedia[0].url ? Api.route(item.multimedia[0].url) : '') + "')"
													}}>
												</div>
											</td>
											<td style={{ width: '120px' }}>
												<Boton onClick={() => agregarAlPedido(item)} color="primary" variant="contained" size="small">Agregar</Boton>
											</td>
										</tr>)}
									</tbody>
									<tfoot>
										<tr>
											<td colSpan="6" className="w3-small">
												<div style={{ width: '100%' }}>
													<div className="w3-cell w3-cell-middle"><span>Filas por página </span></div>
													<div className="w3-cell">
														{paginador.selectCuantos.length > 0 ? <FormControl variant="outlined">
															<Select
																MenuProps={{
																	style: { zIndex: 15000 }
																}}
																size="small"
																labelId="productos-cuantos-label"
																id="productos-cuantos"
																value={paginador.cuantos}
																onChange={(e) => cambioCuantos(e)}
																input={<BootstrapInput />}>
																{paginador.selectCuantos.map((option) => <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>)}
															</Select>
														</FormControl> : null}
													</div>
													<div className="w3-cell w3-cell-middle"><span>{filtros.desde + 1} - {((filtros.desde + paginador.cuantos) > paginador.numResultados) ? paginador.numResultados : filtros.desde + paginador.cuantos} de {paginador.numResultados} </span></div>
													<div className="w3-cell w3-cell-middle">
														<IconButton aria-label="Atrás" onClick={() => atras()} size="small" className={classes.margin} disabled={filtros.desde <= 1}>
															<FontAwesomeIcon icon={faChevronLeft} size="sm" />
														</IconButton>
														<IconButton aria-label="Siguiente" onClick={() => siguiente()} size="small" className={classes.margin} disabled={((filtros.desde + paginador.cuantos) > paginador.numResultados)}>
															<FontAwesomeIcon icon={faChevronRight} size="sm" />
														</IconButton>
													</div>
												</div>
											</td>
										</tr>
									</tfoot>
								</table>
							</div> : null}
						</div>
					</div>
				</form>
				<Divider />
				<div className="w3-row w3-white">
					<div className="w3-col l6" style={{ padding: '30px 16px' }}>
						<button className="w3-btn w3-ripple w3-light-gray w3-block w3-large w3-padding-large w3-text-gray w3-round"
							type="button"
							onClick={() => props.cancelar()}>Cancelar</button>
					</div>
					<div className="w3-col l6" style={{ padding: '30px 16px' }}>
						<button
							className="w3-btn w3-ripple w3-green w3-block w3-large w3-padding-large w3-round"
							type="button"
							onClick={() => guardar()}
							disabled={conPuntoEntrega && envio === null}
						>
							Guardar
						</button>
					</div>
				</div>
			</div>
		</div >
	);
};
export default withRouter(CrearPedido);