import CONFIG from "config";
const Helper = {
	diaMesAnhoSeparador: function (date, separador) {
		return [this.dosDigitos(date.getDate()), this.dosDigitos(date.getMonth() + 1), date.getFullYear()].join(separador);
	},
	dosDigitos: function (numero) {
		if (numero.toString().length === 1) {
			return "0" + numero;
		}
		return numero;
	},
	dateToServerFormat: function (date) {
		return date.toISOString().split('T')[0];
	},
	dateToUiFormat: function (severFormat) {
		let fechaArray = severFormat.split("-");
		let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
		return this.diaMesAnhoSeparador(date, '/');
	},
	datetimeToUiFormat: function (severFormat) {
		let fechaString = severFormat.split(" ")[0];
		let timeString = severFormat.split(" ")[1];
		let fechaArray = fechaString.split("-");
		return fechaArray[2] + '/' + fechaArray[1] + '/' + fechaArray[0] + " " + timeString;
	},
	stringDateToServerFormat: function (dateString) {
		if (dateString.search("/")) {
			return dateString.split("/").reverse().join('-');
		}
		if (dateString.search("-")) {
			return dateString.split("-").reverse().join('-');
		}
	},
	serverFormatToDate: function (serverFormat) {
		let fechaArrayAux = serverFormat.split(" ");
		let fechaArray = fechaArrayAux[0].split("-");
		let horaArray = fechaArrayAux[1].split(":");
		let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
		date.setHours(parseInt(horaArray[0]));
		date.setMinutes(parseInt(horaArray[1]));
		date.setSeconds(parseInt(horaArray[2]));
		return date;
	},
	serverToDDMMYYYYhhmm: function (severFormat) {
		let fechaArrayAux = severFormat.split(" ");
		let fechaArray = fechaArrayAux[0].split("-");
		let horaArray = fechaArrayAux[1].split(":");
		let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
		return this.diaMesAnhoSeparador(date, '/') + ' ' + horaArray[0] + ':' + horaArray[1];
	},
	serverToDDMMYYYY: function (severFormat) {
		let fechaArrayAux = severFormat.split(" ");
		let fechaArray = fechaArrayAux[0].split("-");
		let date = new Date(fechaArray[0], fechaArray[1] - 1, fechaArray[2]);
		return this.diaMesAnhoSeparador(date, '/');
	},
	getFechaServer: function (severFormat) {
		let fecha = null;
		if (severFormat) {
			fecha = severFormat.split(" ")[0];
		}
		return fecha;
	},
	isEmptyObject: function (obj) {
		for (var prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				return false;
			}
		}
		return true;
	},
	getObjectFromArray: function (arrayObjects, key, value) {
		function findObject(object) {
			return object[key] === value;
		}
		return arrayObjects.filter(findObject)[0];
	},
	getObjectsFromArray: function (arrayObjects, key, value) {
		function findObject(object) {
			return object[key] === value;
		}
		return arrayObjects.filter(findObject);
	},
	arrayObjectsToArraySimple: function (arrayObjects, key) {
		let arraySimple = [];
		for (var i in arrayObjects) {
			arraySimple.push(arrayObjects[i][key]);
		}
		return arraySimple;
	},
	haveSameElements: function (array1, array2) {
		let same = true;
		if (array1.length === array2.length) {
			for (var i in array1) {
				if (array2.indexOf(array1[i]) === -1) {
					same = false;
				}
			}
		} else {
			same = false;
		}
		return same;
	},
	momentValido: function (fechaMoment) {
		if ((typeof fechaMoment !== 'undefined') && (fechaMoment !== null) && (fechaMoment !== '') && (typeof fechaMoment !== 'boolean')) {
			return true;
		} else {
			return false;
		}
	},
	momentToYYYYMMDD: function (fechaMoment) {
		let yyyymmdd = null;
		if ((typeof fechaMoment !== 'undefined') && (fechaMoment !== null) && (fechaMoment !== '') && (typeof fechaMoment !== 'boolean')) {
			if (fechaMoment.format('YYYY-MM-DD')) {
				yyyymmdd = fechaMoment.format('YYYY-MM-DD');
			}
		}
		return yyyymmdd;
	},
	// capitalize: function (word) {
	//     return (word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
	// },
	stringToNumUiFormat: function (str) {
		let num = Number(str);
		return num.toLocaleString('ES-es');
	},
	getUrlParameter: function (sParam) {
		let sPageURL = window.location.search.substring(1),
			sURLVariables = sPageURL.split('&'),
			sParameterName,
			i;
		for (i = 0; i < sURLVariables.length; i++) {
			sParameterName = sURLVariables[i].split('=');
			if (sParameterName[0] === sParam) {
				return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
			}
		}
	},
	blobToFile: function (theBlob, fileName) {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	},
	capitalize: function (s) {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	},
	match: function (objeto, texto, campos) {
		let match = false;
		if (texto === '') {
			match = true;
		}
		for (let i in campos) {
			if (typeof objeto[campos[i]] !== 'undefined' && objeto[campos[i]] !== null) {
				if (objeto[campos[i]].includes(texto) || objeto.nombre.includes(Helper.capitalize(texto))) {
					match = true;
				}
			}
		}
		return match;
	},
	log: function (key, value) {
		if (CONFIG.LOGS) {
			console.log(key, value);
		}
	},
	isValidEmail: function (email) {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	},
	numberUi: function (string, lang) {
		string = Number(string);
		string = string.toString();
		return string.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d)\.?)/g, ".");
	},
	armarCategorias: function (jquias) {
		let cats = [{ id: 'todos', nombre: 'Todos', subJerarquias: [] }];
		//nivel 1
		for (let i in jquias) {
			jquias[i].subJerarquias = [];
			if (!jquias[i].jerarquiaPapaId) {
				cats.push(jquias[i]);
			}
		}
		//nivel 2
		for (let i in cats) {
			for (let x in jquias) {
				if (jquias[x].jerarquiaPapaId === cats[i].id) {
					if (cats[i].subJerarquias.length === 0) {
						cats[i].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
					}
					cats[i].subJerarquias.push(jquias[x]);
				}
			}
		}
		//nivel 3
		for (let i in cats) {
			for (let x in cats[i].subJerarquias) {
				for (let y in jquias) {
					if (jquias[y].jerarquiaPapaId === cats[i].subJerarquias[x].id) {
						if (cats[i].subJerarquias[x].subJerarquias.length === 0) {
							cats[i].subJerarquias[x].subJerarquias.push({ id: 'todos', nombre: 'Todos', subJerarquias: [] });
						}
						cats[i].subJerarquias[x].subJerarquias.push(jquias[y]);
					}
				}
			}
		}
		console.log('cats', cats);
		return cats;
	},
	getGeoData: function (json) {
		let datosGeo = {
			direccion: null,
			barrio: null,
			ciudad: null,
			departamento: null,
			pais: null
		};
		if (json && json.results && json.results.length > 0) {
			for (let i in json.results) {
				if (json.results[i].types[0] === "route") {
					datosGeo.calle = json.results[i]['formatted_address'].split(",")[0];
				}
				if (json.results[i].types[0] === "street_address") {
					datosGeo.direccion = json.results[i]['formatted_address'].split(",")[0];
				}
				if (json.results[i].types[0] === "neighborhood") {
					datosGeo.barrio = json.results[i]['formatted_address'].split(",")[0];
				}
				if (json.results[i].types[0] === "locality") {
					datosGeo.ciudad = json.results[i]['formatted_address'].split(",")[0];
				}
				if (json.results[i].types[0] === "administrative_area_level_1") {
					datosGeo.departamento = json.results[i]['formatted_address'].split(",")[0];
				}
				if (json.results[i].types[0] === "country") {
					datosGeo.pais = json.results[i]['formatted_address'].split(",")[0];
				}
			}
			if (!datosGeo.direccion) {
				if (datosGeo.calle) {
					datosGeo.direccion = datosGeo.calle;
				} else if (datosGeo.ciudad) {
					datosGeo.direccion = datosGeo.ciudad;
				}
			}
		}
		return datosGeo;
	}
};
export default Helper;